import { useState } from "react";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import { FiMap, FiMaximize } from "react-icons/fi";
import Modal from "@mui/material/Modal";

import { Icon } from "leaflet";
import { Marker, Popup } from "react-leaflet";
import { useDispatch, useSelector } from "react-redux";
import {
  addItem,
  addItems,
  removeItem,
  removeItems,
} from "../../lib/slices/cartSlice";
import { open } from "../../lib/slices/sidebarSlice";

import MuppiImage from "../../assets/images/M03.jpeg";
import BlueMarker from "../Map/images/monoposte_blue.png";
import RedMarker from "../Map/images/monoposte_red.png";
import GreenMarker from "../Map/images/monoposte_green.png";
import OrangeMarker from "../Map/images/monoposte_orange.png";
import CyanMarker from "../Map/images/monoposte_cyan.png";
import GrayMarker from "../Map/images/monoposte_gray.png";
import _ from "lodash";

import "./markerPopup.scss";

dayjs.extend(weekday);

function MarkerPopupMonoposte(props) {
  const { monoposte, onlyView } = props;
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart.value);
  const monopostes = useSelector((state) => state.monoposte.value);
  const { startDate, endDate } = useSelector((state) => state.search);
  const [isOpen, setOpen] = useState(false);

  const markerBlueMarker = new Icon({
    iconUrl: BlueMarker,
    iconSize: [25, 30],
  });

  const markerRedMarker = new Icon({
    iconUrl: RedMarker,
    iconSize: [25, 30],
  });

  const makerGreenMarker = new Icon({
    iconUrl: GreenMarker,
    iconSize: [25, 30],
  });

  const markerOrangeMarker = new Icon({
    iconUrl: OrangeMarker,
    iconSize: [25, 30],
  });

  const markerCyanMarker = new Icon({
    iconUrl: CyanMarker,
    iconSize: [25, 30],
  });

  const markerGrayMarker = new Icon({
    iconUrl: GrayMarker,
    iconSize: [25, 30],
  });

  const handleIcon = (cart) => {
    return cart.some((pin) => pin.serie === monoposte.serie)
      ? markerBlueMarker
      : monoposte.situacao === "rescindiu"
      ? markerOrangeMarker
      : monoposte.situacao === "oferta"
      ? markerCyanMarker
      : monoposte.situacao === "fantasma"
      ? markerGrayMarker
      : monoposte.situacao === "ativo"
      ? markerRedMarker
      : monoposte.situacao === "disponivel"
      ? makerGreenMarker
      : markerRedMarker;
  };

  const handleAddCart = (monoposte, all = false) => {
    if (!all) {
      const newMonoposte = {
        ...monoposte,
        startDate: startDate
          ? dayjs(startDate).toJSON()
          : dayjs().weekday(3).toJSON(),
        endDate: endDate
          ? dayjs(endDate).toJSON()
          : dayjs().weekday(3).add(1, "week").toJSON(),
      };
      dispatch(addItem(newMonoposte));
    } else {
      let monopostesNetwork = _.filter(
        monopostes,
        (pin) => pin.network === monoposte.network
      );
      monopostesNetwork = _.map(monopostesNetwork, (pin) => {
        return {
          ...pin,
          startDate: startDate
            ? dayjs(startDate).toJSON()
            : dayjs().weekday(3).toJSON(),
          endDate: endDate
            ? dayjs(endDate).toJSON()
            : dayjs().weekday(3).add(1, "week").toJSON(),
        };
      });
      dispatch(addItems(monopostesNetwork));
    }
    dispatch(open());
  };
  const handleRemoveCart = (monoposte, all = false) => {
    if (!all) {
      dispatch(removeItem(monoposte));
    } else {
      dispatch(
        removeItems(
          _.filter(monopostes, (pin) => pin.network === monoposte.network)
        )
      );
    }
    dispatch(open());
  };

  const allNetworkInCart = () => {
    if (monoposte.network !== "") {
      const monopostesFromNetwork = _.filter(
        monopostes,
        (pin) => pin.network === monoposte.network
      );
      const cartFromNetwork = _.filter(
        cart,
        (pin) => pin.network === monoposte.network
      );
      const diff = _.differenceBy(
        monopostesFromNetwork,
        cartFromNetwork,
        "_id"
      );
      if (_.isEmpty(diff)) {
        return true;
      }
    }
    return false;
  };

  return (
    <Marker
      position={[
        monoposte.location.coordinates[0],
        monoposte.location.coordinates[1],
      ]}
      icon={handleIcon(cart)}
    >
      <Popup>
        <div className="marker-popup">
          <div className="marker-popup__header">
            <div className="maximize_img_btn" onClick={() => setOpen(true)}>
              <FiMaximize stroke="#0181BA" size={16} />
            </div>

            <div className="image">
              <img src={MuppiImage} alt="Muppi Imagem" />
            </div>
            <a
              href={`http://maps.google.com/maps?q=&layer=c&cbll=${monoposte.location.coordinates[0]},${monoposte.location.coordinates[1]}`}
              target="_blank"
              className="street_view"
              rel="noreferrer"
            >
              <div className="street_view_link">
                Street view <FiMap stroke="#0181BA" />
              </div>
            </a>
          </div>
          <div className="marker-popup__content">
            {monoposte.concelho && (
              <div className="marker-popup__content__item">
                <div className="title">Concelho: </div>
                <div className="value">{monoposte.concelho}</div>
              </div>
            )}
            {monoposte.network && (
              <div className="marker-popup__content__item">
                <div className="title">Rede: </div>
                <div className="value">{monoposte.network}</div>
              </div>
            )}
            <div className="marker-popup__content__item">
              <div className="title">Serie:</div>
              <div className="value">{monoposte.serie}</div>
            </div>
            <div className="marker-popup__content__availability">
              {monoposte.isFree && monoposte.situacao !== 'ativo' ? (
                <div className="free">Livre para Utilização</div>
              ) : (
                <div className="busy">
                  Ocupado até { (monoposte.until) ? dayjs(monoposte.until).format("DD/MM/YYYY") : '(sem data informada)' }
                </div>
              )}
            </div>
            {!onlyView && (
              <div className="marker-popup__content__buttons">
                {cart.some((pin) => pin.serie === monoposte.serie) ? (
                  <button
                    onClick={() => {
                      handleRemoveCart(monoposte);
                    }}
                    className="border_button"
                  >
                    Remover Equipamento
                  </button>
                ) : (
                  <button
                    onClick={() => {
                      handleAddCart(monoposte);
                    }}
                    className={
                      monoposte.network ? `border_button ${
                        monoposte.isFree ? "" : "disabled"
                      }` : `normal_button ${
                        monoposte.isFree ? "" : "disabled"
                      }`
                    }
                  >
                    Adicionar Equipamento
                  </button>
                )}
                {monoposte.network && (
                  <>
                    {!allNetworkInCart() && (
                      <button
                        onClick={() => {
                          handleAddCart(monoposte, true);
                        }}
                        className={`normal_button ${
                        monoposte.isFree ? "" : "disabled"
                      }`}
                      >
                        Adicionar toda rede
                      </button>
                    )}
                    {allNetworkInCart() && (
                      <button
                        onClick={() => {
                          handleRemoveCart(monoposte, true);
                        }}
                        className="border_button"
                      >
                        Remover toda rede
                      </button>
                    )}
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </Popup>
      <Modal
        open={isOpen}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="image-popup-show">
          <img src={MuppiImage} alt="Muppi Imagem" />
        </div>
      </Modal>
    </Marker>
  );
}

export default MarkerPopupMonoposte;
